import React from 'react'
import styled from 'styled-components'
import Particles from 'react-particles-js';
import { makeStyles } from '@material-ui/core/styles';
import { docsUrl, tokenomicsUrl, roadmapUrl, chartUrl, githubUrl } from 'config/constants/endpoints'
import TwitterCard from './TwitterCard'
import particlesConfigRed from '../../../config/constants/particlesConfigRed'
import projectLogo from '../images/projectLogo.png'
import docs from '../images/docs.png'
import tokenomics from '../images/tokenomics.png'
import roadmap from '../images/roadmap.png'
import chart from '../images/chart.png'
import github from '../images/github.png'

const StyledCustomPage = styled.section`
  // background-image: url('/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 3rem  0;
  position: relative;
  z-index: -12;
  @media only screen and (max-width: 998px) {
    background-size: auto 100%;
  }
`
const MainImage = styled.div`
  height: 25rem;
  width: 20rem;
  margin: 4rem 0 3rem 0;
`
const IconContainer = styled.div`
  width: 100%;
  background-color: rgb(242, 242, 242);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 3em 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`
const Icon = styled.div`
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
`
const InfoCard = styled.div`
  width: 18%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  padding: 1em;
  margin: 0.5em 0;
  border-radius: 0.5em;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
  }
  h1 {
    color: #C70101;
    width: 60%;
    text-align: center;
    font-size: 18px;
  }
  &:hover {
    transform: scale(1.05);
    transition: transform ease 0.2s;
  }
`
const AvatarImage = styled.img`
  width: 60px;
  height: 60px;
  margin: 1em;
  @media only screen and (max-width: 768px) {
    width: 40px;
    height: 40px
  }
`
export const useStyles = makeStyles((theme) => ({
  particlesContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    // zIndex: -10,
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
}));

const routeToURL = (url) => {
  window.location = url
}
const CustomPage = () => {
  const classes = useStyles();
  return (
    <StyledCustomPage>
      <div style={{ opacity: 0.5, zIndex: -9 }}>
        <Particles className={classes.particlesContainer} params={particlesConfigRed} />
      </div>
      {/* <IconContainer>
        <Icon onClick={() => {
          routeToURL("https://bscscan.com/address/0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B")
        }} >
          <img src="/1.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
        <Icon onClick={() => {
          routeToURL("https://poocoin.app/tokens/0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b")
        }}>
          <img src="/2.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
        <Icon onClick={() => {
          routeToURL("https://www.facebook.com/MUFTSWAP")
        }}>
          <img src="/3.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
        <Icon onClick={() => {
          routeToURL("https://www.dextools.io/app/bsc/pair-explorer/0x8858cfb3f8c901381bd52f8acf5bfb888dce421d")
        }}>
          <img src="/4.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
        <Icon onClick={() => {
          routeToURL("https://t.me/muftfantoken")
        }}>
          <img src="/5.webp" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
        <Icon onClick={() => {
          routeToURL("https://medium.com/@MUFT")
        }}>
          <img src="/6.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
        <Icon onClick={() => {
          routeToURL("https://www.reddit.com/r/ManUtdFanToken/")
        }}>
          <img src="/7.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>

        <Icon onClick={() => {
          routeToURL("https://twitter.com/ManUtdFanToken")
        }}>
          <img src="/8.png" alt="" style={{ width: "100%", height: "100%" }} />
        </Icon>
      </IconContainer> */}
      <IconContainer>
        <InfoCard onClick={() => window.open(docsUrl, '_blank')}>
          <AvatarImage src={docs} alt="docs" />
          <h1>Docs</h1>
        </InfoCard>
        <InfoCard onClick={() => window.open(tokenomicsUrl, '_blank')}>
          <AvatarImage src={tokenomics} alt="tokenomics" />
          <h1>Tokenomics</h1>
        </InfoCard>
        <InfoCard onClick={() => window.open(roadmapUrl, '_blank')}>
          <AvatarImage src={roadmap} alt="roadmap" />
          <h1>Roadmap</h1>
        </InfoCard>
        <InfoCard onClick={() => window.open(chartUrl, '_blank')}>
          <AvatarImage src={chart} alt="chart" />
          <h1>Charts</h1>
        </InfoCard>
        <InfoCard onClick={() => window.open(githubUrl, '_blank')}>
          <AvatarImage src={github} alt="github" />
          <h1>Github</h1>
        </InfoCard>


      </IconContainer>
      <MainImage>
        <img src={projectLogo} alt="devil" style={{ width: "100%", height: "100%" }} />
      </MainImage>
      <TwitterCard />
    </StyledCustomPage>
  )
}

export default CustomPage
