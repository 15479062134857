export const docsUrl = 'https://mswapdocs.gitbook.io/'
export const tokenomicsUrl = 'https://mswapdocs.gitbook.io/muft-and-muftswap/tokenomics/mswap'
export const roadmapUrl = 'https://mswapdocs.gitbook.io/muft-and-muftswap/fundamentals/roadmap'
export const chartUrl = 'https://poocoin.app/tokens/0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b'
export const githubUrl = 'https://github.com/muftswap'
export const cmcUrl = 'https://coinmarketcap.com/currencies/muftswap/'
export const cmUrl = 'https://coinmooner.com/coin/6924/'
export const auditUrl = 'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_ManUtdFantoken.pdf'
export const ftxUrl = 'https://blockfolio.com/coin/MUFT?timeFrame=1W'
export const buyMuftUrl = 'https://pancakeswap.finance/swap?outputCurrency=0x1D4a26a9D980AFC2287Dca15eb896A26384bAc7c'
export const iloUrl = 'https://muftswap-1-907945.ingress-baronn.easywp.com/'
export const swapUrl = 'https://pancakeswap.finance/swap?outputCurrency=0x9393d3c08956f245cdee9ac9dd7214131ae2bb8b'
export const liquidityUrl = 'https://pancakeswap.finance/add/BNB/0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B'