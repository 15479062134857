import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeUpdatedIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Swap',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/liquidity'
      },
    ],
  },
  // {
  //   label: 'Red Pools',
  //   icon: 'PoolpdatedIcon',
  //   href: '/farms',
  // },
  {
    label: 'Devil`s Farms',
    icon: 'FarmUpdatedIcon',
    href: '/farms',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  {
    label: 'Red Pools',
    icon: 'MoreIcon',
    href: '/pools',
  },
  {
    label: 'Doom Pit',
    icon: 'DoomPitIcon',
    href: '/doompit',
  },
  {
    label: 'Football Predictions',
    icon: 'PredictionIcon',
    href: '/prediction',
  },
  {
    label: 'Football Games',
    icon: 'GameIcon',
    href: '/game',
  },
  {
    label: 'SNFT Marketplace',
    icon: 'HomeIcon',
    href: '/snft',
  },
  {
    label: 'Audits',
    icon: 'LotteryIcon',
    href: '/audit',
  },
  {
    label: 'ILO',
    icon: 'MoonIcon',
    href: '/ilo',
  },
  {
    label: 'Listings',
    icon: 'MoreIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: '/cmc',
      },
      {
        label: 'CoinMooner',
        href: '/cm',
      },
      {
        label: 'CoinGecko',
        href: '/cg',
      },
      {
        label: 'FTX',
        href: '/ftx',
      },
    ],
  },
  // {
  //   label: 'More',
  //   icon: 'MoreUpdatedIcon',
  //   items: [
  //     // {
  //     //   label: 'Github',
  //     //   href:  '', // 'https://github.com/goosedefi/',
  //     // },
  //     {
  //       label: 'Docs',
  //       href: 'https://docs.manutdfantoken.app/', // 'https://goosedefi.gitbook.io/goose-finance/',
  //     },
  //     // {
  //     //   label: 'Blog',
  //     //   href: '', //  'https://goosefinance.medium.com/',
  //     // },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
